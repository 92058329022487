export const FirebaseConfig = {
	"projectId": "call-taxi-7c69e",
	"appId": "1:369873249000:web:c05a3e889190d051739f5d",
	"databaseURL": "https://call-taxi-7c69e-default-rtdb.firebaseio.com",
	"storageBucket": "call-taxi-7c69e.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAIx4xG5MkWcv8IgjSL00cs6o5JMiXUZEA",
	"authDomain": "call-taxi-7c69e.firebaseapp.com",
	"messagingSenderId": "369873249000",
	"measurementId": "G-YYBB50ZPJH"
};